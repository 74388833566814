<template>
	<div v-if="!isLoading" class="w-100 event-container position-relative">
		<!-- Barra de acciones -->
		<div class="edit-icon-settings d-flex align-items-center">
			<!-- Likes -->
			<span v-if="hasLikes" class="mr-1">
				<feather-icon
					size="20"
					:class="item.likedByMember ? 'text-danger' : 'profile-icon'"
					icon="HeartIcon"
					class="mr-25"
					@click.stop.prevent="toggleLike()"
				/>
				{{ item.totalLikes }}
			</span>
			<!-- Share -->
			<feather-icon
				v-b-tooltip.hover.bottom
				icon="Share2Icon"
				:title="$t('share.tooltip')"
				size="20"
				class="text-primary share-icon zindex-1"
				:class="!isStaff ? 'd-block mr-n50' : 'd-none d-lg-block'"
				@click="isShareModalVisible = true"
			/>
			<!-- Actions -->
			<b-dropdown v-if="isStaff || isStaffOfThis" id="dropdown-dropleft" variant="link" right class="d-block" no-caret>
				<template #button-content>
					<feather-icon icon="SettingsIcon" size="20" class="text-primary mt-25 mt-lg-0 mr-n50 mr-md-50 pr-lg-0 d-block" />
				</template>
				<b-dropdown-text class="bg-light">
					<p class="user-name font-weight-bolder mb-0">
						{{ $t("edit-dropdown.title") }}
					</p>
				</b-dropdown-text>
				<b-dropdown-item :href="getCollectiveUrl(item, '/backoffice/settings')">
					<feather-icon icon="SettingsIcon" size="14" class="mr-50" />
					{{ $t("backoffice.settings.title") }}
				</b-dropdown-item>
				<b-dropdown-item :href="getCollectiveUrl(item)">
					<feather-icon icon="HomeIcon" size="14" class="mr-50" />
					{{ $t("edit-dropdown.enter-staff") }}
				</b-dropdown-item>
				<b-dropdown-item v-if="canEdit" @click="isClassifiersModalVisible = true">
					<feather-icon icon="LayersIcon" size="14" class="mr-50" />
					{{ $t("members.edit.classifiers.name") }}
				</b-dropdown-item>
				<b-dropdown-item v-if="canEdit" @click="isCustomFieldsModalVisible = true">
					<feather-icon icon="ToolIcon" size="14" class="mr-50" />
					{{ $t("members.edit.custom-fields.label") }}
				</b-dropdown-item>
				<b-dropdown-item class="d-block d-lg-none" @click="isShareModalVisible = true">
					<feather-icon icon="Share2Icon" size="14" class="mr-50" />
					{{ $t("share.tooltip") }}
				</b-dropdown-item>
			</b-dropdown>
		</div>

		<!-- Space main info: Image + Basic details -->
		<div v-if="Object.values(item).length > 0">
			<!-- Información organizaciones -->
			<profile-details
				v-if="itemType === 'organizations'"
				:name="translate(item.name)"
				:headline="translate(item.headline)"
				:avatar-placeholder="avatarPlaceholder"
				:profile-image="item.logoURL"
				:header-background="item.bgURL"
				:item="item"
				:item-type="itemType"
				:can-edit="canEdit"
				has-action-buttons
				disable-chat
				:disable-meeting="true"
			/>
			<!-- Información general -->
			<collective-detail-card 
				v-else 
				:item="item" 
				:item-event="item" 
				:is-mobile="isMobile" 
				:is-tablet="isTablet" 
				:item-type="itemType" 
			/>
			<!-- Community White Footer Info -->
		</div>

		<!-- Welcome/Hall Content -->
		<collective-detail-info
			v-if="Object.values(item).length > 0"
			:item="item"
			:is-mobile="isMobile"
			:item-type="itemType"
			:item-type-single="itemTypeSingle"
			@fetchData="fetchData"
		/>

		<!-- Share modal -->
		<share-modal
			v-if="item.key"
			v-model="isShareModalVisible"
			:can-share="canShare"
			:item-name="item.title"
			model-type="communities"
			type="communities"
			:model-key="id"
		/>

		<!-- Classifiers modal -->
		<classifiers-modal
			v-model="isClassifiersModalVisible"
			:item-key="item.key"
			:morph-type="itemTypeSingle"
			:model-type="itemTypeSingle"
			:classifiers="item.classifiers"
			@profile-updated="handleProfileUpdated"
		/>

		<!-- Custom fields modal -->
		<customfields-modal 
			v-model="isCustomFieldsModalVisible" 
			:item="item"
			:morph-type="itemTypeSingle"
			@profile-updated="handleProfileUpdated"
		/>
	</div>
</template>

<script>
import CollectiveDetailCard from "@/views/apps/collectives/components/CollectiveDetailCard.vue";
import CollectiveDetailInfo from "@/views/apps/collectives/components/CollectiveDetailInfo.vue";
import ShareModal from "@/@core/components/modal/ShareModal.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import { getCollectiveUrl } from "@/@core/utils/collective";
import ProfileDetails from "@core/components/profile/ProfileDetails.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import CustomfieldsModal from "@/@core/components/modal/CustomfieldsModal.vue";
import ClassifiersModal from "@/@core/components/modal/ClassifiersModal.vue";
import OrganizationsPlaceholder from "@/assets/images/placeholders/light/organizations-logo.svg";
// import entitiesStoreModule from "@/views/apps/entities/store/entitiesStoreModule";
// import { ENTITIES_STORE_MODULE_NAME, ENTITIES_ACTIONS } from "@/views/apps/entities/constants/entities-store-constants";
// import { PEOPLE_ACTIONS, PEOPLE_STORE_MODULE_NAME } from "@/views/apps/people/constants/people-store-constants";
// import peopleStoreModule from "@/views/apps/people/store/peopleStoreModule";
// import Service from "@/config/service-identifiers";

export default {
	name: "CollectiveDetail",
	components: {
		CollectiveDetailCard,
		ShareModal,
		CollectiveDetailInfo,
		CustomfieldsModal,
		ClassifiersModal,
		ProfileDetails,
	},
	props: {
		itemTypeSingle: {
			type: String,
			required: true,
		},
		itemType: {
			type: String,
			required: true,
		},
		breadcrumbRoute: {
			type: String,
			required: true,
		},
		customItemType: {
			type: String,
			default: null,
		},
		hasLikes: {
			type: Boolean,
			default: false,
		},
		fromWelcome: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			lastLoadedPage: 1,
			isLoading: false,
			isShareModalVisible: false,
			isClassifiersModalVisible: false,
			isCustomFieldsModalVisible: false,
		};
	},
	computed: {
		avatarPlaceholder() {
			return OrganizationsPlaceholder;
		},
		slug() {
			// esta vista puede estar siendo llamada desde 2 niveles diferents (parent y child)
			// console.log('this.$route.params', this.$route.params);
			// si estamos viendo desde el parent, tendremos el "id" del parent y el "slug" del child
			if(this.$route.params.slug){
				return this.$route.params.slug;
			}
			// si estamos directamente en el welcome del child, solo tendremos el "communityId"
			return this.$route.params.communityId;
		},
		itemsData() {
			return this.$store.getters.communitiesOthers[this.itemTypeSingle] || this.$store.getters.communitiesOthers[this.itemType];
		},
		apps() {
			return this.$store.getters.apps;
		},
		item() {
			if (this.fromWelcome) {
				return this.$store.getters.currentCollective;
			}
			if (this.itemsData && this.itemsData?.unpaginated) {
				const items = this.itemsData.unpaginated;
				const item = items.find((item) => item.slug === this.slug);
				return item;
			}
			return this.itemsData || {};
		},
		id() {
			return this.item?.key;
		},
		isStaff() {
			return this.item?.isStaff;
		},
		isStaffOfThis() {
			return this.item?.isStaffOfThis;
		},
		loggedMemberRoles() {
			return this.$store.getters.loggedMemberRoles;
		},
		currentCollective() {
			return this.$store.getters.currentCollective;
		},
		parentCollective() {
			return this.$store.getters.parentCollective;
		},
		canShare() {
			return checkPermissions("share", this.customItemType || this.itemTypeSingle, this.loggedMemberRoles, this.currentCollective);
		},
		isMobile() {
			return window.innerWidth < 576;
		},
		isTablet() {
			return window.innerWidth < 993;
		},
		classifiersData() {
			if (this.$store.getters.classifiers[this.itemTypeSingle]) {
				return this.$store.getters.classifiers[this.itemTypeSingle].unpaginated;
			}
			return [];
		},
		classifiers() {
			return this.classifiersData;
		},
		sortedClassifiers() {
			const newObject = {};
			this.classifiers?.forEach((item) => {
				if (!newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)]) {
					newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)] = [item];
				} else {
					newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)].push(item);
				}
			});
			return newObject;
		},
		canEdit() {
			return checkPermissions("edit", this.customItemType || this.itemTypeSingle, this.loggedMemberRoles, this.currentCollective);
		},
		communitySlugForClassifiersAndFields() {
    		const routeName = this.$route.name;
    		const { mainCommunityID, slug: parentSlug } = this.parentCollective;
    		const { slug: currentSlug } = this.currentCollective;

    		if (routeName === "welcome") {
        		return mainCommunityID ? parentSlug : this.slug;
    		}

    		if (routeName === "organization") {
        		return parentSlug ? currentSlug : this.slug;
    		}
		}

	},
	watch: {
		isMobile(value) {
			return value;
		},
		isTablet(value) {
			return value;
		},
		classifiers(newValue) {
			return newValue;
		},
	},
	async created() {
		//this.isLoading = true;

		await this.fetchData();

		if (this.item && Object.values(this.item).length > 0) {
			// this.getTickets();
			// this.getPlans();
			this.updateBreadcrumbs();
		}
		this.fetchClassifiers();
		this.fetchCustomFields();
		this.isLoading = false;
	},
	methods: {
		async fetchData() {
			if (this.items?.key != null) {
				return;
			}
			const requestConfigForOnlyEvent = {
				orderByDate: 1,
				checkStatus: true,
			};
			console.log('Fetching community data with slug:', this.slug);
			await this.$store.dispatch("getItems", {
				itemType: "communities",
				customName: "communitiesOthers",
				storedKey: this.itemTypeSingle,
				forceAPICall: true,
				page: this.lastLoadedPage,
				perPage: this.perPage,
				communitySlug: this.slug,
				requestConfig: requestConfigForOnlyEvent,
			});
		},
		updateBreadcrumbs() {
			const breadcrumbs = [
				{
					text: `${this.itemType}.breadcrumb-text.${this.itemType}-list`,
					to: { name: this.breadcrumbRoute },
				},
				{
					text: this.translate(this.item.name),
					active: true,
				},
			];
			// console.log('breadcrumbs', breadcrumbs);
			this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
		},
		getCollectiveUrl(item, params = "") {
			if (item != null) {
				return getCollectiveUrl(item, params);
			}
			return "";
		},

		async toggleLike() {
			await this.$store.dispatch("toggleLike", {
				itemType: "communitiesOthers",
				storedKey: this.itemTypeSingle ? this.itemTypeSingle : this.itemType,
				morphType: "community",
				key: this.item.key,
			});
		},
		async fetchClassifiers() {
			await this.$store.dispatch("getItems", {
				storedKey: this.itemTypeSingle,
				itemType: "backoffice/classifiers",
				customName: "classifiers",
				page: 1,
				forceAPICall: true,
				communitySlug: this.communitySlugForClassifiersAndFields,
				requestConfig: {
					[`${this.itemTypeSingle}Key`]: this.item?.key,
				},
			});
		},
		async fetchCustomFields() {
			await this.$store.dispatch("fetchCustomFields", {
				modelType: this.itemTypeSingle,
				communitySlug: this.communitySlugForClassifiersAndFields
			});
		},
		translate(field) {
			return translateTranslationTable(this.$store.state.locale.currentLocale, field);
		},
		valueType(field) {
			if (field.type === "boolean") {
				if (field.value === 1) {
					return this.$t("organizations.edit.custom-fields.boolean-response.true");
				}
				return this.$t("organizations.edit.custom-fields.boolean-response.false");
			}
			return "";
		},
		translateTranslationTable,
		icon(classifier) {
			return classifier.iconClass.split("-").pop();
		},
		classifierStyle(classifier) {
			return {
				color: classifier.fontColor,
				"background-color": classifier.backgroundColor,
			};
		},
		async handleProfileUpdated() {
			await this.fetchData();
			await this.fetchClassifiers();
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";

.event-container {
	width: 100vw;
}
.edit-icon-settings {
	position: absolute;
	right: 10px;
	top: -50px;
	cursor: pointer;
}
</style>
